<template>
  <div>
    <template v-if="selectedFlag">
      <div class="cursor-pointer">
        <div class="available-action flex px-4 py-2" @click="openModal">
          <span class="text-sm ml-2">{{ $t('Mark Reviewed') }}</span>
        </div>
      </div>
    </template>
    <template v-else>
      <vx-tooltip position="bottom" color="warning" :text="`${$t('Please, select issued statements.')}`">
        <div class="cursor-not-allowed">
          <div class="pointer-events-none unavailable-action flex px-4 py-2">
            <span class="text-sm ml-2">{{ $t('Mark Reviewed') }}</span>
          </div>
        </div>
      </vx-tooltip>
    </template>
    <shipblu-prompt
      class="shipment-modal"
      @close="closeModal"
      :active.sync="markReviewedModal"
      :title="`${$t('')}`"
      :buttons-hidden="true">
      <div class="vx-row mb-2">
        <div class="vx-col w-full text-center">
        <p>{{ $t('Are you sure you want to mark') }} {{selected.length}} {{ $t('statements as reviewed?') }}</p>
        </div>
      </div>
      <div class="grid grid-cols-2 gap-4 mt-4">
        <button @click="closeModal" class="btn disable-btn">{{ $t('Cancel') }}</button>
        <button @click="changeStatus" class="active-btn btn">{{ $t('Save') }}</button>
      </div>
    </shipblu-prompt>
  </div>
</template>

<script>
import ShipbluPrompt from '../../../layouts/components/ShipBluPrompt.vue'
import {sendRequest} from '../../../http/axios/requestHelper.js'
import i18nData from '../../../i18n/i18nData'
import common  from '@/assets/utils/common'

export default {
  props: ['selected'],
  data () {
    return {
      selectedFlag: false,
      markReviewedModal: false
    }
  },
  watch: {
    selected (val) {
      if (val.length !== 0 && val.filter(item => item.status === 'ready').length === 0) {
        this.selectedFlag = true
      } else {
        this.selectedFlag = false
      }
    }
  },
  methods: {
    changeStatus () {
      const promises = []
      for (let index = 0; index < this.selected.length; index++) {
        promises.push(this.sendChangeOrder(this.selected[index]))
      }
      Promise.allSettled(promises)
        .then(() => {
          this.$emit('selected', [])
          this.$emit('loadStatements')
          this.markReviewedModal = false
        })
        .catch(() => {
        })
    },
    sendChangeOrder (select) {
      return new Promise((resolve, reject) => {
        const updateStatus = {
          status: 'ready'
        }
        sendRequest(true, false, this, `api/v1/billing/statements/${select.id}/`, 'patch', updateStatus, true, 
          () => {
            resolve({
              status: 200
            })
            this.$vs.notify({
              color:'success',
              title:i18nData[this.$i18n.locale]['Success'],
              text: i18nData[this.$i18n.locale][' has been updated successfully!'],
              position: 'top-center'
            }, (error) => {
              common.notifyResponseError(this, error)
              reject({
                status: 400
              })
            })
          }
        )
      })
    },
    closeModal () {
      this.selected = []
      this.markReviewedModal = false
      this.$emit('selected', [])
    },
    openModal () {
      this.markReviewedModal = true
    }
  },
  components: {
    ShipbluPrompt
  }
}
</script>