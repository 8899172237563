<template>
  <div id="page-user-view">
    <div class="w-full mb-4 p-2 page-title flex items-center data-list-btn-container">
      <div :style="$route.query.tab === tab ? 'background: #1C5BFE; color: white' : ''" class="relative vx-row m-2 py-2 pl-4 pr-5 cursor-pointer d-theme-dark-bg rounded-md hover:shadow-drop"
        v-for="tab in tabs" :key="tab.index" @click="changeTabFilter(tab)">
        <div class="text-sm">{{$t(tab)}}</div>
      </div>
    </div>
    <shipblu-table
      :sst="true"
      orders
      multiple
      v-model="selected"
      pagination
      :max-items="maximumItems"
      :data="statements"
      @search="handleSearch"
      @keyup.enter.native="handleSearch"
      :tableLoader="tableLoader"
      search
    >
      <template slot="header">
        <div class="flex flex-wrap items-center gap-3 whitespace-nowrap">
          <download-button :selected="selected"></download-button>
          <mark-ready-button :selected="selected" @selected="selected = $event" @loadStatements="loadStatements"></mark-ready-button>
        </div>
      </template>
      <template slot="thead">
        <shipblu-th>{{$t('Merchant Name')}}</shipblu-th>
        <shipblu-th>{{$t('Statement Number')}}</shipblu-th>
        <shipblu-th>{{$t('Payout Method')}}</shipblu-th>
        <shipblu-th>{{$t('Balance Due')}}</shipblu-th>
        <shipblu-th>{{$t('Cash Collection Balance')}}</shipblu-th>
        <shipblu-th>{{$t('Payout')}}</shipblu-th>
        <shipblu-th>{{$t('Status')}}</shipblu-th>
        <shipblu-th>{{$t('Statement PDF')}}</shipblu-th>
        <shipblu-th class="flex justify-center">
          <feather-icon icon="ChevronDownIcon" svgClasses="h-5 w-5"/>
        </shipblu-th>
      </template>

      <template slot-scope="{ data }">
        <shipblu-tr class="inactive-link" :data="tr" :key="indextr" v-for="(tr, indextr) in data" :color="`${getStyle(data[indextr].v2 ? data[indextr].v2 : data[indextr])}`">
          <shipblu-td class="sm:hidden block col-span-2 p-0 order-3">
            <!-- line -->
            <p style="background-color: #DCDCDC;height: 1px;"></p>
          </shipblu-td>
          
          <shipblu-td class="order-4" :data="data[indextr].merchant">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Merchant Name')}}</p>
            <p class="sm:text-base text-sm">
              {{ data[indextr].merchant ? data[indextr].merchant.name : '' }}
            </p>
          </shipblu-td>
          
          <shipblu-td :data="data[indextr].number" class="text-left sm:order-3 order-16 sm:pointer-events-none">
            <div class="flex sm:static absolute top-3 gap-2 items-start justify-start transform-50" v-on:click.stop>
              <p class="sm:text-base text-lg flex gap-1 items-start justify-center">
                <span>{{ data[indextr].number }}</span>
              </p>
            </div>
          </shipblu-td>

          <shipblu-td class="order-5" :data="data[indextr].payout">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Payout Method')}}</p>
            <div class="sm:text-base text-sm" v-if="data[indextr].payout_method">
              <p class="capitalize">Type: {{data[indextr].payout_method.bank ? 'Bank' : 'Wallet'}}</p>
              <p v-if="data[indextr].payout_method.bank">Name: {{data[indextr].payout_method.bank.name}}</p>
              <p>Number: {{data[indextr].payout_method.bank ? data[indextr].payout_method.bank.account_number : data[indextr].payout_method.wallet.number}}</p>
            </div>
          </shipblu-td>
          
          <shipblu-td class="order-6" :data="data[indextr].net_service_fees_due">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Balance Due')}}</p>
            <p class="sm:text-base text-sm">
              {{ data[indextr].v2 ? Number(data[indextr].v2.balance_due).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) : Number(data[indextr].net_service_fees_due).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }}
            </p>
          </shipblu-td>

          <shipblu-td class="order-7" :data="data[indextr].net_cod_due">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Cash Collection Balance')}}</p>
            <p class="sm:text-base text-sm">
              {{ data[indextr].v2 ? Number(data[indextr].v2.cash_closing_balance).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) : Number(data[indextr].net_cod_due).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }}
            </p>
          </shipblu-td>

          <shipblu-td class="order-8" :data="data[indextr].net_cod_due">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Payout')}}</p>
            <p class="sm:text-base text-sm">
              {{ data[indextr].v2 ? Number(data[indextr].v2.payout).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) : Number(data[indextr].net_cod_due).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }}
            </p>
          </shipblu-td>

          <shipblu-td class="order-9" :data="data[indextr].status" style="white-space: nowrap;">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Status')}}</p>
            <div class="flex" style="width: fit-content;">
              <span class="capitalize border border-solid rounded-4xl px-5 py-1.5 leading-none sm:text-sm text-xs text-white" :style="`background-color: ${getStyle(data[indextr].v2 ? data[indextr].v2 : data[indextr])}`">{{ $t(getStatusLabel(data[indextr].v2 ? data[indextr].v2 : data[indextr])) }}</span>
            </div>
          </shipblu-td>


          <shipblu-td :data="data[indextr].pdf_link" class="order-10 active-link">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Statement PDF')}}</p>
            <a @click="downloadStatement(data[indextr])" target="_blank" class="sm:text-base text-sm hover:underline">{{$t('PDF')}}</a> / 
            <a @click="downloadExcelStatement(data[indextr])" target="_blank" class="sm:text-base text-sm hover:underline">{{$t('Excel')}}</a> 
          </shipblu-td>
          
          <shipblu-td class="order-2 active-link" style="vertical-align: middle;">
            <div class="md:text-center text-right">
              <vs-dropdown
                vs-trigger-click
                @click="selected = []"
                class="dd-actions cursor-pointer"
              >
                <span class="material-symbols-outlined text-2xl">more_horiz</span> 
                <vs-dropdown-menu style="min-width: 190px;">
                  <vs-dropdown-item @click="generatePDF(data[indextr])">
                    <span class="flex items-center">
                      <feather-icon
                        icon="FilePlusIcon"
                        svgClasses="h-4 w-4"
                        class="mr-2"
                      />
                      <span>{{$t('Generate PDF/Excel')}}</span>
                    </span>
                  </vs-dropdown-item>
                  <vs-dropdown-item @click="regenerateStatement(data[indextr])">
                    <span class="flex items-center">
                      <feather-icon
                        icon="FilePlusIcon"
                        svgClasses="h-4 w-4"
                        class="mr-2"
                      />
                      <span>{{$t('Regenerate Statement')}}</span>
                    </span>
                  </vs-dropdown-item>
                  <vs-dropdown-item :disabled="data[indextr].is_default" @click="newStatement('Edit', data[indextr])">
                    <span class="flex items-center">
                      <feather-icon
                        icon="EditIcon"
                        svgClasses="h-4 w-4"
                        class="mr-2"
                      />
                      <span>{{$t('Edit')}}</span>
                    </span>
                  </vs-dropdown-item>
                  <vs-dropdown-item :disabled="data[indextr].is_default" @click="deleteStatement(data[indextr])">
                    <span class="flex items-center">
                      <feather-icon
                        icon="TrashIcon"
                        svgClasses="h-4 w-4"
                        class="mr-2"
                      />
                      <span>{{$t('Delete')}}</span>
                    </span>
                  </vs-dropdown-item>
                </vs-dropdown-menu>
              </vs-dropdown>
            </div>
          </shipblu-td>
        </shipblu-tr>
      </template>
    </shipblu-table>
    <shipblu-pagination :maximumItems="maximumItems" :totalRows="totalRows" :currentPage="currentPage" @currentPage="currentPage = $event" @changePageSize="changePageSize" />
    <add-statement @file_url="file_url = $event" @loadStatements="loadStatements"
    :type="type" :statementModal="statementModal" @statementModal="statementModal = $event" :statement="statement" @addStatement="addStatement"
    @updateStatement="updateStatement"></add-statement>
  </div>
</template>

<script>
import {sendRequest} from '../../http/axios/requestHelper.js'
import i18nData from '../../i18n/i18nData'
import AddStatement from '../admin/merchant-view/AddStatement.vue'
import common  from '@/assets/utils/common'
import ShipbluTable from '../../layouts/components/NewShipBluTable.vue'
import ShipbluTr from '../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../layouts/components/ShipBluTd.vue'
import DownloadButton from './components/DownloadStatements.vue'
import MarkReadyButton from './components/markReadyButton.vue'
import ShipbluPagination from '../../layouts/components/ShipbluPagination.vue'

export default {
  data () {
    return {
      selected: [],
      maximumItems: localStorage.getItem('maxItems') ? Number(localStorage.getItem('maxItems')) : process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      editable: false,
      tabs: ['Needs Review', 'All'],
      tabsDic: {'0': 'Needs Review', '1': 'All'},
      tabsNameDic: {'Needs Review': 0, 'All': 1},
      activeTab: 0,
      statements: [],
      deleteData: {},
      statementModal: false,
      type: 'Add',
      statement: {},
      offset: 0,
      totalRows: 0,
      currentPage: (this.$route.query.page &&  Number(this.$route.query.page) > 0) ? Number(this.$route.query.page) : 1,
      merchantID: '',
      file_URl: '',
      statementData: {},
      searchVal: '',
      searchInProgress: false,
      searchedValue: ' ',
      tableLoader: false
    }
  },
  watch: {
    '$route.query.tab' () {
      this.activeTab = this.tabsNameDic[this.$route.query.tab]
      this.currentPage = 1
      this.loadStatements()
    },
    currentPage () {
      this.offset = (this.currentPage - 1) * this.maximumItems
      this.selected = []
      this.$router.push({
        query: {
          tab: this.tabsDic[this.activeTab],
          page: this.currentPage
        }
      }).catch(() => {})
      this.loadStatements()
    },
    'activeTab' () {
      this.statements = []
      this.searchVal = ''
      this.$router.push({
        query: {
          tab: this.tabsDic[this.activeTab],
          page: this.currentPage
        }
      }).catch(() => {})
      this.loadStatements()
      this.currentPage = 1
      this.selected = []
    }
  },
  methods: {
    removeAttribute (event) {
      event.preventDefault()
    },
    changePageSize (val) {
      localStorage.setItem('maxItems', val)
      this.maximumItems = val
      this.currentPage = 1
      this.loadStatements()
    },
    handleSearch (search) {
      this.offset = 0
      this.currentPage = 1
      this.searchVal = search.target ? search.target.value : search
      return common.manageSearch(search, this.loadStatmentsSearch)
    },
    loadStatmentsSearch () {
      if (this.searchInProgress || this.searchedValue === this.searchVal) {
        return
      }
      this.loadStatements()
    },
    getStyle (data) {
      return common.getOrderStatusColor(data.status)
    },
    getStatusLabel (order) {
      return common.getStatusLabel(order, this.$store.state.AppActiveUser.userRole)
    },
    getOrderStatusColor (order) {
      return common.getOrderStatusColor(order.status)
    },
    getAccountStatusColor (order) {
      return common.getOrderStatusColor(order.accounting_status)
    },
    changeTabFilter (tab) {
      this.activeTab = this.tabsNameDic[tab]
      this.$router.push({
        query: {
          tab: this.tabsDic[this.activeTab],
          page: this.activeTab,
          filter: this.filters,
          type: this.typeFilter
        }
      }).catch(() => {})
    },
    loadStatements () {
      this.searchInProgress = true
      this.tableLoader = true
      const query = `?${this.activeTab === '0' || this.activeTab === 0 ? 'status=created' : ''}&offset=${this.offset}&search=${this.searchVal}&limit=${this.maximumItems}`
      sendRequest(true, false, this, `api/v1/billing/statements/${query}`, 'get', null, true, 
        (response) => {
          this.statements = response.data.results
          this.tableLoader = false
          this.totalRows = response.data.count
          this.searchedValue = this.searchVal
        }
      )
      this.searchInProgress = false
    },
    downloadStatement (data) {
      sendRequest(false, false, this, `api/v1/billing/statements/${data.id}/pdf/`, 'get', null, true, 
        (response) => {
          this.file_URl = response.data.file_url_v2 !== '' ? response.data.file_url_v2 : response.data.file_url
          window.open(this.file_URl, '_blank')
        }
      )
    },
    downloadExcelStatement (data) {
      sendRequest(false, false, this, `api/v1/billing/statements/${data.id}/excel/`, 'get', null, true, 
        (response) => {
          this.file_URl = response.data.file_url_v2 !== '' ? response.data.file_url_v2 : response.data.file_url
          window.open(this.file_URl, '_blank')
        }
      )
    },
    generatePDF (data) {
      sendRequest(false, false, this, `api/v1/billing/statements/${data.id}/pdf/`, 'post', {}, true,
        () => {
          this.$vs.notify({
            color:'success',
            title:i18nData[this.$i18n.locale]['Success'],
            text:i18nData[this.$i18n.locale]['New PDF has been generated successfully. Click download!'],
            position: 'top-center'
          })
        }
      )
    },
    regenerateStatement (data) {
      sendRequest(false, false, this, `api/v1/billing/statements/${data.id}/regenerate/`, 'get', null, true,
        () => {
          this.$vs.notify({
            color: 'success',
            title: i18nData[this.$i18n.locale]['Success'],
            text: i18nData[this.$i18n.locale]['Statements has been regenerated successfully'],
            position: 'top-center'
          })
        }
      )
    },
    newStatement (type, data) {
      this.type = type
      if (type === 'Add') {
        this.statement = {
          number: '',
          net_service_fees_due: '',
          net_cod_due: '',
          status: '',
          accounting_status: '',
          expected_net_cod_deposit_date: '',
          pdf_link: '',
          merchant: '',
          discount: ''
        }
      } else {
        this.statement = data
      }
      this.statementModal = true
    },
    addStatement () {
      const statement = { 
        number: this.statement.number,
        net_service_fees_due: this.statement.net_service_fees_due,
        net_cod_due: this.statement.net_cod_due,
        status: this.statement.status,
        accounting_status: this.statement.accounting_status,
        expected_net_cod_deposit_date: this.statement.expected_net_cod_deposit_date,
        pdf_link: this.file_url,
        merchant: this.statement.merchant
      }
      sendRequest(false, false, this, 'api/v1/billing/statements/', 'post', statement, true,
        () => {
          this.statementModal = false
          this.loadStatements()
        }
      )
    },
    updateStatement () {
      const statement = { 
        number: this.statement.number,
        net_service_fees_due: this.statement.net_service_fees_due,
        net_cod_due: this.statement.net_cod_due,
        status: this.statement.status,
        accounting_status: this.statement.accounting_status,
        expected_net_cod_deposit_date: this.statement.expected_net_cod_deposit_date,
        pdf_link: this.file_url,
        merchant: this.statement.merchant.id,
        opening_balance: Number(this.statement.opening_balance)
      }
      sendRequest(false, false, this, `api/v1/billing/statements/${this.statement.id}/`, 'patch', statement, true,
        () => {
          this.statementModal = false
          this.loadStatements()
        }
      )
    },
    deleteStatement (data) {
      this.deleteData = data
      this.$vs.dialog({
        color: 'danger',
        title: 'Confirm',
        text: i18nData[this.$i18n.locale]['Are you sure to delete?'],
        accept: this.confirmDelete
      })
    },
    confirmDelete () {
      sendRequest(false, false, this, `api/v1/billing/statements/${this.deleteData.id}/`, 'delete', null, true, 
        () => {
          this.loadStatements()
        }
      )
    }
  },
  components: { 
    AddStatement,
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    DownloadButton,
    MarkReadyButton,
    ShipbluPagination
  },
  created () {
    this.activeTab = this.$route.query.tab ? this.tabsNameDic[this.$route.query.tab] : '0'
    this.offset = (this.currentPage - 1) * this.maximumItems
    this.loadStatements()
  }
}
</script>
<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
.con-pagination-table{
  display: none;
}
</style>
